import { groq } from '#imports';

export function getQuery({ slug, isDraft = false, previewId } = {}) {
  if (isDraft) {
    return groq`*[_type == "mainArticleCard" && slug.current == "${slug}" && uuid == "${previewId}"] | order(_updatedAt desc)[0]{
      ...,
      'uuid': false,
      article->{
        slug,
        image,
        size,
        title,
        subtitle,
        titleColor,
        subtitleColor,
        shadowColor,
        isFullImage,
        backgroundColor,
        isArticleExternalSourceType,
        externalSource,
        crew[]{staff->, speciality->}
      },
    }`;
  }

  return groq`*[_type == "mainArticleCard" && !(_id in path('drafts.**'))][0]{
      ...,
      'uuid': false,
      article->{
        slug,
        image,
        size,
        title,
        subtitle,
        titleColor,
        subtitleColor,
        shadowColor,
        isFullImage,
        backgroundColor,
        isArticleExternalSourceType,
        externalSource,
        crew[]{staff->, speciality->}
      },
    }`;
}
