<script setup>
import { computed, useArticleAuthors, useImageSource } from '#imports';

const props = defineProps({
  articleCard: {
    type: Object,
    required: true,
  },
});

const theme = computed(() => props.articleCard.theme);
const article = computed(() => props.articleCard.article);
const title = computed(() => article.value.title);
const subtitle = computed(() => article.value.subtitle);
const articleImage = computed(() => article.value?.image);
const caption = computed(
  () => articleImage.value?.caption || `Изображение-${title.value}`
);
const authors = useArticleAuthors(article.value.crew);
</script>

<template>
  <div class="main-header-card">
    <picture>
      <source
        media="(min-width: 768px)"
        :srcset="`
        ${useImageSource({
          image: articleImage,
          height: 900,
        })} 1x, ${useImageSource({
          image: articleImage,
          height: 900,
          dpr: 2,
        })} 2x`"
      />
      <NuxtImg
        preload
        class="main-header-card__image"
        :alt="caption"
        width="500"
        height="900"
        sizes="xl:100vw lg:100vw md:100vw sm:100vw xs:100vw"
        :src="
          useImageSource({
            image: articleImage,
            width: 500,
            height: 900,
            fit: 'crop',
            crop: 'center',
            dpr: 1,
          })
        "
        :srcset="`${useImageSource({
          image: articleImage,
          width: 500,
          height: 900,
          fit: 'crop',
          crop: 'center',
          dpr: 2,
        })} 2x`"
      />
    </picture>
    <div class="main-header-card__container">
      <div class="main-header-card__theme">{{ theme }}</div>
      <h1 class="main-header-card__title">{{ title }}</h1>
      <p class="main-header-card__subtitle">{{ subtitle }}</p>
      <div v-if="authors" class="main-header-card__authors">
        <PostCardAuthors :authors="authors" avatar-size="medium" is-main-card />
      </div>
    </div>
    <div class="main-header-card__shadow"></div>
  </div>
</template>

<style lang="postcss" scoped>
.main-header-card {
  position: relative;
  height: 754px;

  &__image {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: var(--content-max-width);
    height: 100%;
    margin: 0 auto;
    padding: 0 60px 30px;
  }

  &__theme {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: var(--accent-second-color);
    margin-bottom: 9px;
  }

  &__title {
    max-width: 1075px;
    font-family: var(--title-font-family);
    color: var(--title-light-color);
    font-weight: var(--title-font-weight);
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 16px;
    transition: var(--default-transition) color;
  }

  &__subtitle {
    max-width: 1075px;
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
    font-family: var(--body-font-family);
    color: #fff7fb;
  }

  &__authors {
    display: flex;
    justify-content: flex-end;
  }

  &__shadow {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    background-color: var(--accent-color);

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      bottom: 110px;
      height: 200px;
      background: linear-gradient(
        180deg,
        transparent 0%,
        rgba(var(--accent-color--rgb), 0.7) 60.42%,
        var(--accent-color) 100%
      );
    }
  }

  &:hover {
    .main-header-card__title {
      color: #ffd1f2;
    }
  }

  @media (--large-vp) {
    height: calc(100vh - var(--main-header-height));
  }

  @media (--small-vp) {
    height: var(--cover-heigth-mobile);

    &__container {
      padding: 0 12px 12px;
    }

    &__title {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 30px;
    }

    &__subtitle {
      margin-bottom: 12px;
      font-size: 22px;
      line-height: 24px;
      opacity: 0.6;
    }
  }
}
</style>
